<template>
  <router-view />
</template>

<script>
import "@/assets/js/common";
export default {
  name: "DefaultLayout",
};
</script>

<style scoped>
@import "~@/assets/css/default/reset.css";
@import "~@/assets/css/default/normalize.css";
@import "~@/assets/css/default/common.css";
@import "~@/assets/css/default/content.css";
@import "~@/assets/css/default/ckeditor.css";
@import "~@/assets/css/default/custom.css";
</style>
